import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import BillingMixin from "@/components/Billing/Billing.mixin";
import { debounce } from "throttle-debounce";
import Spinner from "@/components/Loading/Spinner";
import FireAlert from "@/components/Alert/FireAlert.vue";
//import WarningPrice from "@/components/Alert/WarningPrice.vue";

const {
  aMONTH_DISCOUNT
} = require("../../../common/monthDiscount");
export default {
  mixins: [BillingMixin],
  components: {
    Spinner,
    FireAlert
    //WarningPrice,
  },
  props: {
    skipButton: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true,
      validate: v => ["first", "prolong", "change", "addLimit"].includes(v)
    },
    preferredPlanId: {
      type: Number,
      default: null
    },
    preferredPeriod: {
      type: Number,
      default: null
    },
    state: {
      type: Object,
      default: () => ({})
    },
    subscriptionAddLimit: {
      type: Object,
      default: null
    },
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    var _window$sessionStorag, _window$sessionStorag2;
    const periods = [{
      text: "1 месяц",
      value: 1
    },
    //&: BLACK_FRIDAY-PROMO: 2023-11-20
    {
      text: "3 месяца / скидка 10%",
      value: 3,
      /*
      discount: {
          type: "quantity",
          quantity: 3,
          percent: 10,
      },
      */
      discount: aMONTH_DISCOUNT[0]
    }, {
      text: "6 месяцев / скидка 20%",
      value: 6,
      /*
      discount: {
          type: "quantity",
          quantity: 6,
          percent: 20,
      },
      */
      discount: aMONTH_DISCOUNT[1]
    }, {
      text: "12 месяцев / скидка 30%",
      value: 12,
      /*
      discount: {
          type: "quantity",
          quantity: 12,
          percent: 30,
      },
      */
      discount: aMONTH_DISCOUNT[2]
    }, {
      text: "24 месяца / скидка 50%",
      value: 24,
      discount: aMONTH_DISCOUNT[3]
    }];
    const paymentMethods = this.getPaymentMethods();
    const promocodeAllowed = !!window.sessionStorage.getItem("promocode:show");
    const promocode = promocodeAllowed && ((_window$sessionStorag = window.sessionStorage.getItem("promocode:code")) === null || _window$sessionStorag === void 0 || (_window$sessionStorag2 = _window$sessionStorag.toUpperCase) === null || _window$sessionStorag2 === void 0 ? void 0 : _window$sessionStorag2.call(_window$sessionStorag));
    return {
      loading: false,
      plans: null,
      plan: null,
      periods,
      //period: null,
      period: 3,
      paymentMethods,
      paymentMethod: paymentMethods[0].value,
      calculation: null,
      lastCalculation: null,
      currentSubscriptions: null,
      periodsCalculations: null,
      showPromocode: !!promocode,
      promocodeAllowed,
      promocode: promocode || "",
      promocodeDebounced: promocode || "",
      promocodeDebouncedFunction: null,
      ...this.state,
      addLimitButtonFeedBack: null
    };
  },
  async mounted() {
    return Promise.all([this.loadPlans(), this.loadState()]);
    //await this.loadPlans();
    //await this.loadState();
  },
  methods: {
    openCarrotquestChat() {
      window.carrotquest.open();
    },
    getId(id) {
      return `${this._uid}-${id}`;
    },
    async loadPlans() {
      this.plans = await this.$store.dispatch("billing/getPlans");

      //console.log('PF.265:1: this.plans=', this.plans) //china

      this.plans.splice(this.plans.findIndex(p => p.id == 5), 1);

      //addLimit
      if (this.type == "addLimit") {
        if (this.preferredPlanId == 30) {
          this.plans = this.plans.filter(el => {
            return el.id >= 30 && el.id <= 39;
          });
        }
      } else {
        this.plans = this.plans.filter(el => {
          return el.id < 10;
        });
      }

      //console.log('PF.349: this.plans=', this.plans)

      /*
      if (this.preferredPlanId) {
          const plan = this.plans.find((i) => i.id === this.preferredPlanId);
          if (plan) {
              this.plan = plan.id;
              return;
          }
      }
      this.plan = this.plans[0].id;
      */
    },
    async loadState() {
      this.currentSubscriptions = (await this.$store.dispatch("billing/getState")).currentSubscriptions;
    },
    async calculate() {
      this.calculation = null;
      if (!this.orderData) {
        return;
      }

      //console.log('402: this.orderData=',this.orderData)
      if (this.type == "addLimit") return;

      /*
      //console.log('355: this.orderData=',this.orderData)
      //console.log('355: this.subscriptionAddLimit=',this.subscriptionAddLimit)
      if( this.type=='addLimit'){
          this.orderData.items[0].plan.date_end = this.subscriptionAddLimit.date_end;
      }
      //console.log('360: this.orderData=',this.orderData)
      */

      this.calculation = await this.$store.dispatch("billing/calculateOrder", this.orderData);
      this.lastCalculation = this.calculation;
    },
    async onSubmit() {
      if (!this.calculation) {
        return;
      }
      const orderData = {
        ...this.orderData
      };
      if (this.paymentMethod === "bank_invoice") {
        let organization = await this.$store.dispatch("billing/getCurrentOrganization").catch(e => {
          var _e$response;
          return ((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status) === 404 ? null : Promise.reject(e);
        });
        if (!organization) {
          organization = await this.$modal.process(() => import("@/components/Billing/ModalCustomerOrganization"), {});
        }
        orderData.organization = organization;
      }
      if (this.calculation.total === 0 && this.type === "change") {
        orderData.paymentMethod = null;
      }
      await this.pay({
        order: orderData,
        calculation: this.calculation
      });
      this.$emit("complete");
    },
    async togglePromocodeInput() {
      this.showPromocode = !this.showPromocode;
      if (this.showPromocode) {
        await this.$nextTick();
        this.$refs.promocodeInput.focus();
      }
    },
    async onClickAddLimit() {
      const param = {
        //name: this.user.name,
        email_address: this.user.user_email_address,
        phone_number: this.user.user_phone,
        addLimit: this.orderData.items[0].plan.name
      };
      //console.log('457: param=', param)

      const feedback = await this.$store.dispatch("feedback/sendAddLimit", {
        ...param
      });
      this.addLimitButtonFeedBack = feedback;

      //alert("onClickAddLimit");
    }
  },
  computed: {
    orderData() {
      if (!this.period) {
        return null;
      }
      const discount = this.periods.find(i => i.value === this.period).discount;
      const paymentMethod = this.paymentMethod;
      const discounts = discount ? [discount] : [];
      if (this.promocodeDebounced) {
        discounts.push({
          type: "promocode",
          code: this.promocodeDebounced
        });
      }
      switch (this.type) {
        case "prolong":
          if (!this.currentSubscriptions) {
            return null;
          }
          return {
            type: this.type,
            items: this.currentSubscriptions.map(subscription => ({
              plan: subscription.plan,
              months: this.period,
              discounts
            })),
            paymentMethod
          };
        default:
          if (!this.plan) {
            return null;
          }
          return {
            type: this.type,
            items: [{
              plan: this.plans.find(i => i.id === this.plan),
              months: this.period,
              discounts
            }],
            paymentMethod
          };
      }
    },
    plansOptions() {
      if (!this.plans) {
        return null;
      }
      let availablePlans = this.plans || [];
      if (this.type === "change") {
        if (!this.currentSubscriptions) {
          return null;
        }
        availablePlans = availablePlans.filter(plan => !this.currentSubscriptions.find(i => i.plan.id === plan.id));
      }
      return availablePlans.map(plan => ({
        text: this.type === "addLimit" ? `${plan.name}` : `${plan.name} - ${plan.price} р. / месяц`,
        value: plan.id
      }));
    },
    periodsOptions() {
      if (this.type !== "change") {
        return this.periods;
      }
      if (!this.periodsCalculations) {
        return null;
      }
      return this.periods.filter((period, i) => {
        const next = this.periodsCalculations[i + 1];
        if (next && next.total === 0) {
          return false;
        }
        return true;
      });
    },
    promocodeComputed: {
      get() {
        return this.promocode;
      },
      set(v) {
        this.promocode = v.toUpperCase();
      }
    },
    promocodeResult() {
      var _this$lastCalculation;
      let appliedPromocode = null;
      let declinedPromocode = null;
      (((_this$lastCalculation = this.lastCalculation) === null || _this$lastCalculation === void 0 ? void 0 : _this$lastCalculation.items) || []).forEach(item => {
        let itemAppliedPromocode = item.appliedDiscounts.find(i => i.type === "promocode");
        if (itemAppliedPromocode) {
          appliedPromocode = itemAppliedPromocode;
        }
        let itemDeclinedPromocode = item.declinedDiscounts.find(i => i.type === "promocode");
        if (itemDeclinedPromocode) {
          declinedPromocode = itemDeclinedPromocode;
        }
      });
      if (declinedPromocode) {
        var _declinedPromocode;
        switch ((_declinedPromocode = declinedPromocode) === null || _declinedPromocode === void 0 ? void 0 : _declinedPromocode.reason) {
          case "notFound":
            return {
              type: "error",
              text: "Данный промокод не подходит"
            };
          case "expired":
            return {
              type: "error",
              text: "У промокода истек срок действия"
            };
          case "used":
            return {
              type: "error",
              text: "Данный промокод уже был использован"
            };
          case "firstPaymentOnly":
            return {
              type: "error",
              text: "Данный промокод подходит только для первой оплаты"
            };

          //&:BLACK-FRIDAY
          case "1Month":
            return {
              type: "error",
              text: "Данный промокод не подходит для оплаты одного месяца"
            };
          default:
            return {
              type: "error",
              text: "Не удалось применить промокод"
            };
        }
      }
      if (appliedPromocode) {
        return {
          type: "success",
          text: `Шикарно! Скидка ${appliedPromocode.percent}%`
        };
      }
      return null;
    }
  },
  watch: {
    orderData: {
      immediate: true,
      async handler() {
        this.calculation = null;
        await this.$nextTick();
        await this.calculate();
        this.loading = false;
      }
    },
    $data: {
      deep: true,
      handler() {
        this.$emit("state", this.$data);
      }
    },
    plansOptions(options) {
      if (!options) {
        this.plan = null;
        return;
      }
      let preferred = this.preferredPlanId;
      if (!options.find(option => option.value === preferred)) {
        preferred = null;
      }
      if (this.plan) {
        if (!options.find(option => option.value === this.plan)) {
          this.plan = preferred || options[0].value;
        }
      } else {
        this.plan = preferred || options[0].value;
      }
    },
    async plan(plan) {
      this.periodsCalculations = null;
      if (!plan || this.type !== "change") {
        return;
      }
      this.periodsCalculations = await Promise.all(this.periods.map(async period => this.$store.dispatch("billing/calculateOrder", {
        type: "change",
        items: [{
          plan: this.plans.find(i => i.id === plan),
          months: period.value,
          discounts: period.discount ? [period.discount] : []
        }]
      })));
    },
    periodsOptions: {
      immediate: true,
      handler(options) {
        if (!options) {
          this.period = null;
          return;
        }

        //let preferred = this.preferredPeriod || 1;
        let preferred = this.preferredPeriod || 3;
        if (!options.find(option => option.value === preferred)) {
          preferred = null;
        }
        if (this.period) {
          if (!options.find(option => option.value === this.period)) {
            this.period = preferred || options[0].value;
          }
        } else {
          this.period = preferred || options[0].value;
        }
      }
    },
    promocode(v) {
      this.loading = true;
      if (!this.promocodeDebouncedFunction) {
        this.promocodeDebouncedFunction = debounce(500, v => this.promocodeDebounced = v);
      }
      this.promocodeDebouncedFunction(v);
    },
    promocodeResult(v) {
      if (window.sessionStorage.getItem("promocode:code") && v.type === "error") {
        window.sessionStorage.removeItem("promocode:code");
      }
    }
  }
};