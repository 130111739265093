export default {
  name: "FireAlert",
  props: {
    light: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: require("@/assets/images/icons/flame.svg")
      //&: не удалять:заготовки иконок
      //default: require('@/assets/images/icons/christmas_tree_winter_icon.svg'),
      //default: require("@/assets/images/icons/increase.svg"),
    }
  },
  methods: {
    openCarrotquestChat() {
      window.carrotquest.open();
    }
  }
};