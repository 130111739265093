//* &: /MP/salesfinder/common/monthDiscount.js
"use strict";

const aMONTH_DISCOUNT = [
  //&: BLACK-FRIDAY-PROMO: 2023-11-20
  {
    type: "quantity",
    quantity: 3,
    percent: 10,
  },

  {
    type: "quantity",
    quantity: 6,
    percent: 20,
  },

  {
    type: "quantity",
    quantity: 12,
    percent: 30,
  },

  {
    type: "quantity",
    quantity: 24,
    percent: 50,
  },
];

const aMONTH_DISCOUNT_PERIOD = [
  1,
  ...aMONTH_DISCOUNT.map((t) => t.quantity),
  //3, 1, 6, 12
];

module.exports = { aMONTH_DISCOUNT, aMONTH_DISCOUNT_PERIOD };
