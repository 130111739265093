var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "fire-alert"
  }, [_c('div', {
    staticClass: "fire-alert__content",
    class: {
      light: _vm.light
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.image,
      "alt": "Flame"
    }
  }), _c('p', {
    staticClass: "fire-alert__text"
  }, [_vm._v(" Лучшее предложение: рассрочка без % и первого взноса "), _c('br'), _vm._v(" Получите самую низкую цену! "), _c('a', {
    on: {
      "click": _vm.openCarrotquestChat
    }
  }, [_vm._v(" Связаться с менеджером >> ")])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };